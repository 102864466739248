@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");

a {
  text-decoration: none;
}
a:hover {
  color: #1f2022;
}

.navigation-sec {
  position: fixed;
  background: transparent;
  height: 300px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  transition: background-color 0.5s ease-out;
}

.navigation-sec:hover {
  background-color: white;
  color: #1f2022;
}

.NavBar-upSec {
  display: flex;
}

/* search icon section */
.searchIcon-sec {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 180px;
}
.SearchTxt {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Montserrat";
}
.Fasearch {
  color: white;
  margin-right: 20px;
}
.BlackFaSearch {
  color: #1f2022;
  margin-right: 1.5vw;
}
.search-feild {
  background: transparent;
}
::placeholder {
  color: #1f2022;
  font-weight: 500;
  font-size: 14px;
  font-family: sans-serif;
  font-family: "Montserrat";
}

.no-outline:focus {
  outline: none;
}

/* site-logo section */
.logo-sec {
  justify-content: center;

  display: flex;
  height: 180px;
}

.logo-sec img {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100px;
  justify-content: center;
}
/* contact and booking section */
.contact-Booking-sec {
  height: 180px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
}

.Blackcontact-Booking-sec {
  height: 180px;
  color: #1f2022;
  display: flex;
  align-items: center;
  text-align: center;
}
.call-icon-sec {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  justify-content: center;
}
.faphone {
  align-items: center;
  justify-content: center;
}

.booking-contact-txt-sec {
  margin-top: 13px;
  display: flex;
  align-items: center;
  text-align: center;
}
.Blackbooking-btn-sec {
  margin-top: 13px;
  display: flex;
  align-items: center;
  text-align: center;
}

.Blackbooking-btn-sec p {
  font-weight: 600;

  font-size: 0.85rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.contact-Booking-sec p {
  font-weight: 400;
  font-size: 0.85rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.Blackbooking-contact-txt-sec {
  margin-top: 13px;
  display: flex;
  align-items: center;
  text-align: center;
}

.Blackbooking-contact-txt-sec p {
  font-weight: 400;
  font-size: 0.85rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.booking-btn-sec {
  display: flex;
  align-items: center;
}
.book-btn {
  color: white;
  font-weight: 400;
  font-size: 0.85rem;
  margin-left: 15px;
  border: 1px solid white;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
}
.Blackbook-btn {
  color: #1f2022;
  font-weight: 400;
  font-size: 0.85rem;
  margin-left: 15px;
  border: 1px solid #1f2022;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
}
/* NavBar lower sec */
.NavBar-LowSec {
  justify-content: center;

  height: 100px;
  position: relative;
  display: flex;
  color: white;

  align-items: center;
}

.titles {
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}
.NavBar-LowSec p {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 0.85rem;
  cursor: pointer;
}

.BlackNavBar-LowSec {
  height: 100px;
  display: flex;
  color: #1f2022;
  justify-content: center;
  align-items: center;
}
.titles-scroll {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.title-sec {
  align-items: center;
  text-decoration: none;
  list-style: none;
  display: flex;
}
.titleItem {
  padding-top: 10px;
  margin-top: 13px;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.5s;
}

.BlackNavBar-LowSec p {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 0.85rem;
  cursor: pointer;
  align-items: center;
}

/* hover boder */
.titleItem::after {
  content: "";
  position: absolute;
  background-color: #1f2022;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.titleItem:hover::after {
  width: 100%;

  transform: scale(1, 1);
}

/* .titleItem:hover {
  border-bottom: 1px solid #1f2022;
} */

/* scrolled Navigation bar */
.scrolledNavigation-sec {
  justify-content: space-between;
  position: fixed;
  background: white;
  height: 100px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1000;
  transition: 0.5s ease-out;
}

.hide {
  display: none;
}

.subNav-sec {
  background-color: green;
}

.AviyanaSubMenu {
  position: absolute;
  background-color: white;
  left: 0;
  height: 0;
  top: 28vh;
  overflow: hidden;
}
.ScrolledSubMenu {
  position: absolute;
  background-color: white;
  left: 0;
  height: 0;
  top: 9vh;
  overflow: hidden;
}
.p1 {
  visibility: hidden;

  color: #e40000;
}

/* subTitles */
/* .subTitle-sec {
  position: absolute;
  z-index: 1001;
  top: 30vh;
  background-color: aqua;
  width: 100%;
  height: 50px;
  transition: height 0.5s;
}

.titles:hover + .subTitle-sec {
  height: 300px;
} */

@media only screen and (max-width: 1400px) {
}
