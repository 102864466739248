@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.SlideImage-full-sec {
  display: flex;
}

.image-slider {
  position: relative;
  width: 600px;
  height: 465px;
}
.slider-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slick-arrow.slick-prev {
  position: absolute;
  top: 100%;
  left: 90%;
  cursor: pointer;
}

.slick-arrow.slick-next {
  position: absolute;
  right: 0%;
  cursor: pointer;
}

.nav-arrow {
  font-size: larger;
}

.slider-header1 {
  font-family: Martel;
  font-weight: 400;
  font-size: 36px;
}
.slider-desc {
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
}
.slider-link {
  font-family: poppins-regular;
  font-weight: 600;
  letter-spacing: 0.109rem;
  font-size: 14px;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  color: #b57d4f;
}
.slider-link:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}

.Arrow2,
.Arrow1 {
  font-size: 35px;
  font-weight: bolder;
  color: #b57d4f;
}

.Arrow1:hover,
.Arrow2:hover {
  color: #b57d4f;
  cursor: pointer;
}
.Arrow2 {
  display: none;
}

.Arrow1 {
  padding-left: 10px;
  float: right;
}
.slidingImage-sec1 {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .slidingImage-sec {
    width: 700px;
    height: auto;

    padding-left: 0px;
    display: flex;
  }

  .slidingImage-sec img {
    width: 700px;
    align-items: center;
    display: flex;
  }
}

@media only screen and (max-width: 1400px) {
  .slidingImage-sec {
    width: 830px;
    padding-left: 10px;
  }
  .img-sec-slider {
    width: 700px;
  }
  .ImgageSliderTxt-sec {
    text-align: left;
    justify-content: left;
  }
  .slider-data-sec {
    margin-left: -80px;
    justify-content: left;
    text-align: left;
  }

  .slider-header1 {
    justify-content: left;
    text-align: left;

    padding-bottom: 15px;
    font-weight: 400;
    font-size: 36px;
  }
  .slider-desc {
    justify-content: left;
    text-align: left;
    padding-right: 40px;
    margin: 0;
    padding-bottom: 15px;
    font-family: poppins-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7rem;
  }

  .slider-link {
    width: 400px;
    margin-left: -15px;
    font-family: poppins-regular;
    font-weight: 600;
    letter-spacing: 0.109rem;
    font-size: 14px;
    line-height: 1.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 300ms;
    color: #b57d4f;
  }
}
