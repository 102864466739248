@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.villa-bar-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.villa-bar {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.villa-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Martel;
  color: #b57d4f;
}

.villa-des {
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: poppins-regular;
}
.featureItem {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
}
.ApproximateSize-details {
  display: flex;
  width: 30%;
  justify-content: space-between;
}

.Amenities-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: poppins-regular;
}
