.chalet-header {
  width: 100%;

  background-image: url("../images/imgRoom1.png");
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-size: cover;
  height: 630px;
  display: block;
  position: relative;
}
/* video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
} */

.TheAviyana-icon11 {
  width: 70px;
}

.header-txt {
  color: white;
  position: absolute;

  top: 50%;
}

.header-txt p {
  font-size: 15px;
  text-transform: uppercase;
}
.header-txt h1 {
  font-size: 4rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.header-txt h4 {
  font-size: 15px;
  font-weight: lighter;
}

.CheckDate {
  display: block;
}

.Chalets-header-img-sec1 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/img1.png");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}

.Chalets-header-img-sec2 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/img2.png");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}

.Chalets-header-img-sec3 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/img3.png");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}
.Chalets-header-img-sec4 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/img4.png");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}
.Chalets-header-img-sec5 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/img5.jpg");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}
.Chalets-header-img-sec6 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/charlets/MapNew.jpg");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}

.plan-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.plan-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}
@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
}
