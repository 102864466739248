.DestinationHome-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  height: 87vh;
  border-bottom: 2px solid #b57d4f;
  display: block;
  position: relative;
  /* background-image: url(../../images/Selected/spa/spaHome.png); */
  background-size: cover;
  background-position: center;
}
.vidooo {
  position: relative;
  width: 100%;
  height: 200px;
}
.img-area1 {
  position: relative;
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;

  width: 600px;
  height: 500px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
}
.homeVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
}
.boxVideo {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: 0;
}

.home-header-txt1 {
  color: white;
  width: 100%;

  position: absolute;
  left: 0vw;

  bottom: 5vh;
}

.home-header-sec {
  display: flex;

  width: 20%;
  justify-content: center;
  align-items: center;
  position: relative;
  animation-name: headerBannerDesAnimation;
  animation-duration: 40s;

  animation-iteration-count: infinite;
}
.home-header-sec img {
  width: 320px;
  border-bottom: 2px solid #b57d4f;
}

.home-header-txt1 h1 {
  padding-left: 2.2vw;
  padding-right: 2.2vw;
  border-bottom: 2px solid #b57d4f;
  font-size: 3.3rem;
  font-weight: lighter;
  text-align: left;
  text-align: center;
  font-family: Martel;
  letter-spacing: 3px;
}

.home-des-sec {
  z-index: 1500;
  text-align: justify;
  justify-content: center;
  color: white;
  width: 20%;
  position: relative;
  animation-name: headerBannerDesAnimation;
  animation-duration: 40s;

  animation-iteration-count: infinite;
}

@keyframes headerBannerDesAnimation {
  0% {
    left: 5%;
  }
  50% {
    left: 74%;
  }
  100% {
    left: 5%;
  }
}

.home-header-txt1 h4 {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  font-family: poppins-bold;
}
.home-des-sec p {
  display: flex;
  justify-content: center;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  font-size: 12px;
  margin-bottom: 0.5vh;
  font-family: Martel;
  padding-bottom: 5px;
}

.Header-Content1 {
  display: flex;
  justify-content: center;
}

.Destination-header-img-sec1 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../../images/Selected/Wedding/Destination/DestinationLandscape1.png");
  border-top: 3px solid #b57d4f;
}

.spa-header-img-sec2 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../../images/Selected/spa/spaLandscape2.png");
  border-top: 3px solid #b57d4f;
}

/* responsive */
@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
  .home-header-txt h1 {
    font-size: 2rem;
  }

  .home-header-txt1 h4 {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: poppins-bold;
  }

  .home-header-sec img {
    width: 300px;
    border-bottom: 2px solid #b57d4f;
  }
}

@media only screen and (max-width: 2000px) {
  .home-header-txt {
    color: white;
    position: absolute;
    left: 8.5vw;
    bottom: 6.5vh;
  }
  .home-header-txt p {
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.9vh;
    font-family: Montserrat;
  }
  .home-header-txt h1 {
    font-size: 3rem;
    font-weight: lighter;
    font-family: Martel;
  }
  .home-header-txt h4 {
    margin-top: -2px;
    font-size: 0.9rem;
    letter-spacing: 1px;
    font-family: poppins-regular;
    font-weight: 200;
  }
}

@media only screen and (max-width: 1350px) {
  .home-header-txt1 h4 {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: poppins-bold;
  }

  .home-header-sec img {
    width: 250px;
    border-bottom: 2px solid #b57d4f;
  }
}
@media only screen and (max-width: 1110px) {
  .home-header-txt1 h4 {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: poppins-bold;
  }

  .home-header-sec img {
    width: 200px;
    border-bottom: 2px solid #b57d4f;
  }
}
