@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

/* huge bar */
.stay-huge-sec {
  position: relative;
  width: 100%;
  height: 100px;
  align-items: center;
  display: flex;
  background-color: #0b0b45;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border-bottom: 2px solid #b57d4f;
  border-top: 2px solid #b57d4f;
}

.stay-sec {
  position: relative;
  width: 100%;
  background-color: #0b0b45;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.stay-bar {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}

.stay-huge-part {
  display: flex;
  align-items: center;
  justify-content: left;
}

.stay-huge-part .inputHeader {
  align-items: center;
  text-align: left;
}

.stay-in-touch-sec {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
  color: white;

  height: auto;
}
.stay-header {
  font-size: 30px;
  width: 220px;
  margin-right: 10px;
  padding-right: 10px;
  text-align: left;

  display: flex;
  font-family: Martel;
}

.input-sec {
  padding-left: 10px;
  padding-right: 10px;

  width: 175px;
  display: inline-block;
  justify-content: center;
  align-items: center;
}
.inputHeader {
  font-size: small;
  font-family: Montserrat;
}
.data-input {
  font-size: small;
  position: relative;
}
/* 
.data-input svg {
  width: 20px;
  padding-left: 0px;
} */

.data-input {
  display: flex;
  font-size: medium;
  color: rgb(212, 212, 212);
  font-family: Martel;
  cursor: pointer;
}

.data-input input {
  background-color: transparent;
  border: none;
  color: white;
}

/* .img-sec-stay img {
  display: flex;
  margin-left: 10px;
  width: 20px;
} */

.arrowHead {
  display: flex;
  margin-left: 10px;
  width: 20px;
  transition: all 0.5s;
  transform-origin: 50% 50%;
}
.arrowHeadClicked {
  rotate: 180deg;
  display: flex;
  margin-left: 10px;
  width: 20px;
  transition: all 0.5s;
  transform-origin: 50% 50%;
}

.data-input ::placeholder {
  justify-content: center;
  color: rgb(212, 212, 212);
  font-size: medium;
  font-family: Martel;
}
.no-outline:focus {
  outline: none;
}

.stay-book-btn {
  justify-content: center;
  align-items: center;
}

.Country-drop-sec {
  position: absolute;
  height: 30vh;
  top: 70%;
  color: black;
  background-color: white;
  overflow-y: scroll;
  cursor: pointer;
  font-family: Montserrat;
  width: fit-content;
}

.Country-drop-sec ul {
  list-style: none;
  text-align: left;
  width: fit-content;
  display: block;
}

.Country-drop-sec ul li a {
  color: black;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}
.hide {
  display: none;
}
.stay-left-sec {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 10vw;
}

.stay-middile-sec {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}

.middle-down {
  align-items: center;
  display: flex;

  margin-bottom: 10px;
}
.middle-up {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.stay-right-sec {
  align-items: center;
  display: flex;

  justify-content: center;
}

.stay-book-btn input {
  background: transparent;
  padding: 12px 35px;
  color: lightgray;
  font-size: 12px;
  border: 1px solid lightgray;
  font-family: Montserrat;
}
/* second stay bar */
.stay-sec-small {
  width: 100vw;
  display: none;
  height: 230px;
  align-items: center;
  background-color: #0b0b45;
}
.stay-small-bar {
  padding-top: 15px;
  width: 100%;
  padding-bottom: 15px;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.tay-sec-small-part {
  width: 30vw;
  text-align: left;
}
.stay-sec-small-part .inputHeader {
  font-family: Montserrat;

  text-align: left;
}
.stay-sec-small-part .input-sec {
  padding-top: 10px;
  padding-bottom: 10px;
}

.stay-small-header {
  font-size: 25px;
  font-family: Martel;
}
.stay-small-btn input {
  margin-top: 10px;
  background: transparent;
  padding: 12px 35px;
  color: lightgray;
  font-size: 12px;
  border: 1px solid lightgray;
  font-family: Montserrat;
}

.stay-small-Country-drop-sec {
  position: absolute;
  height: 30vh;
  top: 70%;
  color: black;
  background-color: white;
  overflow-y: scroll;
  cursor: pointer;
  font-family: Montserrat;
  width: fit-content;
}
/* @media only screen and(max-width:2000px) {
  .data-input input {
    background-color: transparent;
    border: none;
    color: white;
  }
  .stay-bar {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .input-sec {
    padding-right: 10px;
    width: 15vw;
    display: inline-block;
    justify-content: center;
    align-items: center;
  }
  .select-arrow22 {
    position: absolute;
    right: 20%;
  }
  .stay-left-sec {
    width: 10vw;
  }
  .stay-header {
    font-size: 22px;
  }
  .inputHeader {
    font-size: 13px;
  }
} */

@media only screen and (max-width: 1400px) {
  .data-input input {
    background-color: transparent;
    border: none;
    color: white;
  }
  .stay-bar {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .input-sec {
    padding-right: 10px;

    display: inline-block;
    justify-content: center;
    align-items: center;
  }
  .select-arrow22 {
    position: absolute;
    right: 20%;
  }
  .stay-left-sec {
    width: 10vw;
  }
  .stay-header {
    font-size: 22px;
  }
  .inputHeader {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1200px) {
  .stay-huge-sec {
    display: none;
  }
  .stay-sec-small {
    display: flex;
  }
}

@media only screen and (max-width: 1108px) {
  .stay-sec {
    display: none;
  }
  .stay-sec-small {
    display: flex;
  }
  .stay-sec-small-part ::placeholder {
    font-size: 12px;
  }
  .stay-sec-small-part .data-input {
    font-size: 12px;
  }

  .inputHeader {
    font-size: 10px;
  }

  .arrowHead {
    display: flex;
    margin-left: 10px;
    width: 15px;
    transition: all 0.5s;
    transform-origin: 50% 50%;
  }
  .arrowHeadClicked {
    rotate: 180deg;
    display: flex;
    margin-left: 10px;
    width: 15px;
    transition: all 0.5s;
    transform-origin: 50% 50%;
  }
}
