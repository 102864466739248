@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.footer-bar-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #b57d4f;
}
.footer-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footer-sec {
  display: flex;

  justify-content: center;
  align-items: center;
}

.footer-sec {
  padding-top: 10px;
}
.Gold {
  color: #b57d4f;
}
.footer-desc {
  color: #b57d4f;

  font-family: poppins-regular;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.05rem;
  line-height: 1.5rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}
.partner-logo {
  text-align: start;
}
.partner-logo img {
  filter: grayscale(100%);
  max-height: 57px;
  max-width: 100px;

  object-fit: contain;
}

.main-logo img {
  width: 180px;
  height: 100%;
  vertical-align: middle;
}

.social-icon-fb {
  border: 1px solid #b57d4f;
  color: #b57d4f;
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  margin: 3px;
  position: relative;
  width: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.social-icon-fb:hover {
  opacity: 0.5;
  transition: all 0.3s ease;
}

.social-icon {
  height: 22px;
  fill: #b57d4f;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
}
.footer-link a {
  color: #b57d4f;
  text-align: start;
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.05rem;
  line-height: 2.1rem;
  background-color: transparent;
  text-decoration: none;
}
.footer-nav-button {
  background: transparent;
  border: none;
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.05rem;
  line-height: 2.1rem;
}
.footer__hotel-chevron {
  height: 5px;
  margin-left: 8px;
  transition: all 0.3s ease;
  width: 7px;
}

.copyright-text {
  padding-bottom: 2px;
  padding-top: 20px;
  text-align: center;
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.05rem;
  line-height: 1.5rem;
}

.footer-sec {
  justify-content: center;
  text-align: center;
}

.partner-logo,
.main-logo,
.footer-social-links,
.footer-link-sec {
  justify-content: center;
  text-align: center;
}
@media only screen and (max-width: 1000px) {
  .footer-sec {
    justify-content: center;
    text-align: center;
  }
  .partner-logo,
  .main-logo,
  .footer-social-links {
    justify-content: center;
    text-align: center;
  }
}
