@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.Pop-up-sever-sec {
  z-index: 1112;
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  height: 100vh;
}
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  padding-top: 270px;
  background-color: #ecebde;
  width: 100%;
}
.home-sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-header {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header h3 {
  font-family: Martel;
  font-size: 17px;
}
.select-sec {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
.select-list {
  font-family: poppins-regular;
  border: none;
  padding: 10px;
  width: 100%;
}
.server-submit-btn-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.server-submit-btn-sec input {
  padding: 10px 30px;
  background-color: transparent;
  border: 1px solid #b57d4f;
  text-transform: uppercase;
  transition: 0.3s all linear;
}
.server-submit-btn-sec input:hover {
  color: white;
  background-color: #b57d4f;
}

/* enquire form */
.enquire-from-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.enquire-from-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.enquire-from-area {
  width: 100%;
}

.enquire-site-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.enquire-site-logo img {
  width: 100px;
}

.enquire-header-txt {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enquire-header-txt h3 {
  font-size: 30px;
  font-family: Martel;
  text-align: center;
}
.enquire-input-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.enquire-input-item {
  text-align: left;
  justify-content: left;
}
.enquire-input-item label {
  font-size: 15px;
  font-family: poppins-regular;
  padding-bottom: 5px;
  width: 100%;
}
.enquire-item {
  display: flex;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  margin-bottom: 20px;
}
.enquire-input-item select option {
  font-size: 15px;
  font-family: Martel;
  background-color: transparent;
  border: none;
}
.txtInput {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
.EnquireSelecteddate {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
.enquire-input-sec textarea {
  width: 100%;
  background-color: transparent;
}
.agreeCheck {
  margin-right: 20px;
}
