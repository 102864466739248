.chalet-subnav-bar-sec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chaler-subnav-bar {
  width: 80%;
  display: flex;

  align-items: center;
  justify-content: center;
}
.chaler-subnav-list {
  display: flex;
  width: 100%;
}

.chaler-subnav-item {
  display: flex;
  font-size: 12px;
  margin: 10px 20px;
}

.chaler-subnav-item {
  position: relative;

  cursor: pointer;
}
.chaler-subnav-item:hover {
  color: black;
}

.chaler-subnav-item::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.chaler-subnav-item:hover::after {
  width: 100%;

  transform: scale(1, 1);
}
