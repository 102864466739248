.sideAviyana {
  position: absolute;

  background-color: white;
  width: 100%;
  color: white;
}

.sideAviyana.clicked {
  display: none;
}

.sideAviyana li {
  padding-bottom: 10px;
}
.nav-item1 {
  text-decoration: none;
  position: relative;
  color: black;
  font-size: 0.95rem;
  padding-bottom: 2px;
  margin-right: 2rem;
  padding: 3px 5px;
  border-radius: 5px;
}
.nav-item1:hover {
  color: black;
}
.nav-item1::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.nav-item1:hover::after {
  width: 100%;

  transform: scale(1, 1);
}
