@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

img {
  display: block;
  margin: auto;
  width: 100%;
}
.gal-content {
  position: relative;
  cursor: pointer;
}
.gal-icon {
  color: white;
}
.gal-content .gal-image-desc {
  color: white;
  padding: 0 0 0 5px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.gal-content:hover .gal-image-desc {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.gal-image {
  height: 250px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}
.gal-content .gal-image-user {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  padding: 0 0 0 5px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.gal-content:hover .gal-image-user {
  opacity: 1;
  transition: 0.5s ease-in-out;
}
.fadein::after {
  content: "";
  background: black;
  width: 100%;
  height: 98%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.8s ease;
}
.fadein:hover::after {
  opacity: 0.4;
}

.gallary-header-sec {
  width: 100%;
  padding-left: 20vw;
  padding-right: 20vw;
}

.gal-header {
  color: #b57d4f;
  font-family: Martel;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 2.5rem;
  padding-left: 15vw;
  padding-right: 15vw;
  justify-content: center;
  padding-bottom: 20px;
}
.gal-desc {
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 20px;
  padding-left: 12vw;
  padding-right: 12vw;
  line-height: 1.5rem;
  justify-content: center;
  text-align: center;
}
.gal-image-desc {
  font-family: poppins-regular;
  font-weight: 500;
  font-size: 16px;
}
.gal-image-user {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 12px;
}

/* Pop-Up window */
.popup-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);

  z-index: 1001;
  align-items: center;
}
.popup-body {
  width: 45%;
  height: 95%;
  background-color: white;
  margin: auto;
}
.popup-user-name,
.popup-name-tag {
  text-decoration: none;
  color: #666;
  cursor: pointer;
}
.popup-user-name:hover,
.popup-name-tag:hover {
  color: #666;
}
.popup-user-name {
  font-family: "Lato", sans-serif;

  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.popup-close-button {
  font-size: 24px;
  height: 49px;
  width: 49px;
  cursor: pointer;
}
.popup-name-tag {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
}
.popup-caption {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.075rem;
  line-height: 2.1rem;
  color: #666;
}

.popup-timestamp {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
}

.view-previmg-btn {
  appearance: none;
  background: #000;
  border: none;
  box-sizing: border-box;
  color: #fff;
  display: block;
  font-size: 18px;
  outline: 0;
  padding: 15px;
  text-align: center;
  width: 100%;
  background-color: #b57d4f !important;
  font-family: "Source Sans Pro", sans-serif;
}

.gallery-img-sec {
  width: 1450px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 2000px) {
  .gallary-header-sec {
    width: 100%;
    padding-left: 200px;
    padding-right: 200px;
  }

  .gal-header {
    font-family: Martel;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 2.5rem;
    padding-left: 15vw;
    padding-right: 15vw;
    justify-content: center;
    padding-bottom: 20px;
  }
  .gal-desc {
    font-family: poppins-regular;
    font-weight: 400;
    font-size: 20px;
    padding-left: 12vw;
    padding-right: 12vw;
    line-height: 1.5rem;
    justify-content: center;
    text-align: center;
  }
  .gallery-img-sec {
    width: 100%;
  }
}
@media only screen and (max-width: 1400px) {
  .gallary-header-sec {
    width: 100%;
    padding-left: 200px;
    padding-right: 200px;
  }

  .gal-header {
    font-family: Martel;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 2.5rem;
    padding-left: 15vw;
    padding-right: 15vw;
    justify-content: center;
    padding-bottom: 20px;
  }
  .gal-desc {
    font-family: poppins-regular;
    font-weight: 400;
    font-size: 20px;
    padding-left: 12vw;
    padding-right: 12vw;
    line-height: 1.5rem;
    justify-content: center;
    text-align: center;
  }
  .gallery-img-sec {
    width: 100%;
  }
}
