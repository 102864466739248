@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.headMsj-bar-sec {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.headMsj-bar {
  display: flex;
  width: 80%;

  justify-content: center;
  align-items: center;
}

.headMsj-part {
  display: flex;
  margin-top: 10vh;
  justify-content: center;
  text-align: center;
}

.headMsj-img-sec {
  display: flex;
  justify-content: center;
  border-top: 3px solid #b57d4f;
  border-bottom: 3px solid #b57d4f;
  padding-left: 30px;
  margin-bottom: 5vh;
  padding-right: 30px;
}

.headMsj-img {
  width: 550px;
}

.headMsj-txt-sec {
  display: flex;
  justify-content: center;
}
.headMsj-txt {
  width: 550px;
}

.headMsj-header {
  font-size: 35px;
  font-family: Martel;
  color: #b57d4f;
  padding-bottom: 25px;
}
.headmsj-des {
  text-align: justify;
  font-family: poppin;
  font-size: 16px;
}
@media only screen and (max-width: 1430px) {
  .headMsj-img {
    width: 530px;
  }
  .headMsj-txt {
    width: 530px;
  }
}
@media only screen and (max-width: 1400px) {
  .headMsj-img {
    width: 550px;
  }
  .headMsj-txt {
    width: 550px;
  }
}
