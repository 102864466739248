@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.experience-sec {
  width: 100%;
  display: flex;
  position: relative;
}
.exp-header {
  justify-content: center;
  text-align: center;
  font-family: Martel;
  font-weight: 400;
  font-size: 36px;
  color: #b57d4f;
}
.ex-slider-btn1 {
  display: flex;
  z-index: 1000;
  position: absolute;
  left: 110px;
  top: 55%;
}
.ex-slider-btn2 {
  display: flex;
  z-index: 1000;
  position: absolute;
  right: 110px;
  top: 55%;
}
.ExslidingImage-sec {
  display: inline;
}
.ex-slider-arrow {
  font-size: 30px;
  color: #b57d4f;
}
.ex-slider {
  display: block;

  position: relative;
  text-align: center;
  justify-content: center;
}
.ex-slider-img {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.text-on-image {
  color: white;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
}
.text-on-image p {
  position: relative;
  top: 70%;
  bottom: 0;
  left: 0;
  right: 0;
}

.ex-slider-img img {
  width: 100%;
  text-align: center;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-left: auto;
  justify-content: center;
}

.text-on-image h1 {
  justify-content: center;
  text-align: center;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 36px;
}

.ex-slider-link {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #b57d4f;
  display: flex;
  align-items: center;
  gap: 5px;

  display: flex;
  justify-content: center;
  transition: 500ms;
}
.ex-slider-link:hover {
  text-decoration: underline;
  color: #b57d4f;
  gap: 20px;
  transition: 500ms;
}
