.spa-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  height: 87vh;
  border-bottom: 2px solid #b57d4f;
  display: block;
  position: relative;

  background-size: cover;
  background-position: center;
}
video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
}
.header-txt {
  color: white;
  position: absolute;

  top: 50%;
}

.header-txt p {
  font-size: 15px;
  text-transform: uppercase;
}
.header-txt h1 {
  font-size: 4rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.header-txt h4 {
  font-size: 15px;
  font-weight: lighter;
}

.CheckDate {
  display: block;
}

.spaHome-header-img-sec1 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
  background-image: url("../images/Selected/spa/replace/spaHomeland1.png");
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
}

.meditaionSec {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
}
