.Dining-service-submenu {
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  list-style: none;
  text-align: start;
  justify-content: center;
}

.section {
  width: fit-content;
  display: block;
  list-style: none;
  padding-left: 5vw;
  padding-top: 2vh;
}

.section h3 {
  color: black;
  margin-left: 15px;
  text-align: center;
}

.Dining-service-submenu.clicked {
  display: none;
}

.Dining-service-submenu li {
  display: flex;
  cursor: pointer;
  list-style: none;
  padding-left: 5vw;
  padding-bottom: 5px;
}

.submenu-item {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  cursor: pointer;
}

.submenu-item::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.submenu-item:hover::after {
  width: 100%;

  transform: scale(1, 1);
}
