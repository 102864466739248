@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.Wellness-header {
  width: 100%;
  height: 87vh;
  background-image: url("../../images/wellnessHome.webp");
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-size: cover;
  height: 630px;
  display: block;
  position: relative;
}
.luxuryStaycation {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}
.luxuryStaycation-bar {
  display: block;
  justify-content: center;
  align-items: center;

  width: 60%;
}
.luxuryStay-header {
  display: flex;
  font-family: Martel;
  justify-content: center;
  align-items: center;
}
.Wellness-des-sec {
  display: flex;
  align-items: center;
}
.Wellness-des {
  display: block;
  padding-right: 10vw;
  justify-content: left;
  width: 80%;
  font-family: poppins-regular;
}
.luxuryStay-call-and-book-sec {
  display: flex;
  text-align: center;
  justify-content: right;

  width: 30%;
}
.luxuryStay-calland-book {
  display: block;
  justify-content: center;
}
.luxuryStay-call {
  font-family: poppins-regular;
  justify-content: center;
}

.luxuryStay-book-btn {
  font-family: Montserrat;
  font-size: 12px;
  padding: 10px 30px;
  background: transparent;
  border: 1px solid black;
  transition: all linear 0.3s;
}
.luxuryStay-book-btn:hover {
  background-color: #0b0b45;
  color: white;
}

/* getaway sec */
.getaway-sec {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.getaway-bar {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 75%;
}
.getawayCard {
  padding: 10px;
}
.getawayImg-sec {
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 20px;
}

.getawayImg-sec img {
  width: 100%;
  transition: 0.5s;
  height: 100%;
  transition: transform 1s ease;
}
.getawayImg-sec img:hover {
  transform: scale(1.2);
}

.getaway-details {
  text-align: center;
}
.getaway-header {
  font-family: Martel;
  padding-left: 20px;
  padding-right: 20px;
}

.getaway-link {
  cursor: pointer;
}
