/* new css */
.navigation-sub-title-sec {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.navigation-sub-nav-bar {
  display: flex;
  width: 80%;
  text-align: center;
}

.navigation-sub-nav-part {
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;

  margin-left: 5px;
  margin-right: 5px;
}
.subNav-part-sec1 {
  width: 80%;

  display: flex;
  justify-content: center;
}
ul.sub-title-list {
  text-align: center;
  width: 100%;

  justify-content: center;
  margin-left: auto;
  margin-bottom: auto;
}

.subnav-header-sec {
  display: flex;
  width: 90%;
  align-items: center;

  justify-content: center;
}
.subNavHeader {
  display: flex;
  width: 100%;
  color: black;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
.subnav-list-item {
  display: flex;

  text-align: center;
  justify-content: center;
  width: 90%;
}

/* .Aviyanaservice-submenu.clicked {
  display: none;
} */

.sub-title-list li {
  display: block;
  text-align: center;
  cursor: pointer;
  list-style: none;
  font-size: 12px;

  justify-content: center;
}

.submenu-item1 {
  position: relative;
  cursor: pointer;
}
.submenu-item1:hover {
  color: black;
}

.submenu-item1::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.submenu-item1:hover::after {
  width: 100%;

  transform: scale(1, 1);
}
