#navigationHeader1 {
  background-color: #ecebde;
  border-bottom: 3px solid #b57d4f;
}
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

a {
  color: black;
}

.p1 {
  background-color: #ecebde;
  display: block;
}

.hide {
  display: none;
}

.navigationBar-outer-sec2 {
  z-index: 1111;
  position: fixed;
  background-color: #ecebde;
  width: 100%;
}

.navigationBar-outer-sec2:hover {
  height: auto;
  background-color: #ecebde;
  transition: all 0.5s;
}
.New-nav-bar-sec {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.new-nav-bar {
  width: 98%;
}

.new-nav-upper-sec {
  display: flex;
}
.nav-bar-upper-part {
  width: 33%;
  font-family: Montserrat;
  margin: 10px;
  display: flex;
  align-items: center;
}
.faSearch {
  display: flex;
  align-items: center;
  color: white;

  margin-right: 20px;
}

.blackfaSearch {
  display: flex;
  align-items: center;
  color: #1f2022;
  margin-right: 20px;
}

::placeholder {
  color: #1f2022;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.no-outline:focus {
  outline: none;
}
.searchTxt {
  margin-top: 15px;
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.BlacksearchTxt {
  background: transparent;
  color: #1f2022;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.center {
  padding: 0%;
  margin: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar-upper-part a img {
  width: 150px;
  padding: 0%;
  margin: 0%;
}

.booking-and-contact-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.booking-and-contact-list li {
  list-style-type: none;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: white;
}
.booking-item {
  color: white;
}

.Blackbooking-item {
  color: #1f2022;
}

.faphone {
  color: white;
}

.Blackfaphone {
  color: #1f2022;
}
.new-navbar-booking-btn {
  background-color: transparent;
  padding: 10px 25px;
  border: 1px solid white;
  color: white;
}
.blacknew-navbar-booking-btn {
  background-color: transparent;
  padding: 10px 25px;
  border: 1px solid #b57d4f;
  color: #1f2022;
  transition: linear 0.5s;
}

.blacknew-navbar-booking-btn:hover {
  cursor: pointer;
  background-color: #b57d4f;
  color: white;
  border: 1px solid white;
}

/* nav bar lower sec */
.new-lower-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-item-list {
  width: 90%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation-item-list li {
  list-style: none;
  justify-content: center;
  margin: 1px;

  text-align: center;
  width: fit-content;
}
.NavBar-title {
  font-size: 20px;

  color: white;
  font-family: Martel;
}
.BlackNavBar-title {
  font-size: 20px;

  position: relative;
  color: #1f2022;
  font-family: Martel;
}

/*  hover effecte on txt*/

.BlackNavBar-title:hover {
  color: #1f2022;
}

.BlackNavBar-title::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.BlackNavBar-title:hover::after {
  width: 100%;

  transform: scale(1, 1);
}
/* hover effect end on txt */

/* scrolled nav bar */
.scroll-navbar {
  display: flex;
  width: 100%;
  color: #1f2022;
  position: fixed;
  background-color: #ecebde;
  z-index: 1111;
}

.scrollednew-lower-sec {
  display: flex;

  justify-content: center;
  align-items: center;
}

.scrollednew-lower-sec ul li {
  align-items: center;
  display: flex;
}

.scrolled-booking-btn {
  background-color: transparent;
  padding: 15px 45px;
  border: 1px solid #1f2022;
  color: #1f2022;
  font-size: 20px;
  transition: linear 0.5s;
}
.scrolled-booking-btn:hover {
  cursor: pointer;
  background-color: #b57d4f;
  color: white;
  border: 1px solid white;
}

/* sub drop down menu */
.ScrolledSubMenu {
  background-color: #ecebde;
  position: absolute;
  top: 100%;
}

/* moblie nav bar */
.mobile-nav-sec {
  position: fixed;
  z-index: 1111;
  display: none;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  animation-name: mobileNavShow;
  animation-duration: 0.5s;
}

.ScolledMobile-nav-sec {
  position: fixed;
  z-index: 1111;
  display: none;
  justify-content: center;
  background-color: #ecebde;
  width: 100%;
}
.mobile-nav-sec:hover {
  background-color: #ecebde;
}

.mobile-nav-bar {
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
}
.mobile-nav-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.left {
  justify-content: left;
}
.center {
  justify-content: center;
}
.right {
  justify-content: right;
}

.mobile-nav-item img {
  width: 120px;
  margin: 10px;
  cursor: pointer;
}

.Justify-btn {
  margin-right: 25px;
  color: white;
  cursor: pointer;
}

.BlackJustify-btn {
  margin-right: 25px;
  color: #1f2022;
  cursor: pointer;
}

.Mobilephone {
  color: white;
  cursor: pointer;
}
.BlackMobilephone {
  color: #1f2022;
  cursor: pointer;
}

/* Mobile nav titles section */
.mobile-main-navbar-sec {
  width: 100%;
  height: 500px;
  background-color: #ecebde;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1500;

  animation-name: mobileNavShowing-animation;
  animation-duration: 1s;
}
.mobile-main-navbar {
  display: flex;
  width: 95%;
}
.mobile-main-navbar-list {
  display: inline;
  height: 400px;
  overflow-y: scroll;
}
.mobile-main-nav-item {
  display: block;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  font-family: poppins;
  text-align: left;
  cursor: pointer;
}

.cross-icon {
  cursor: pointer;
}

@keyframes mobileNavShowing-animation {
  0% {
    opacity: 0;
    width: 0;
    left: 0px;
    top: 0px;
  }
  100% {
    opacity: 1;
    width: 100%;
    left: 0px;
    top: 0px;
  }
}

@keyframes mobileNavShow {
  0% {
    opacity: 0;
    height: 0;
    left: 0px;
    top: 0px;
  }
  100% {
    opacity: 1;
    height: auto;
    left: 0px;
    top: 0px;
  }
}

/* mobile-sub-sub nav bar */
.mobile-sub-sub-navbar-sec {
  display: none;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  background-color: #ecebde;
  z-index: 1505;
  position: fixed;
  animation-name: mobileNavShowing-animation;
  animation-duration: 0.5s;
}

.mobile-sub-sub-navbar {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 50px;
}

.sub-sub-navbar {
  height: 400px;
  padding-left: 20px;
  width: 100%;
  overflow-y: scroll;
}
.back-btn {
  display: inline;
  cursor: pointer;
}

.mobile-sub-sub-navbar ul li {
  list-style: none;
  padding-left: 20px;
  margin: 10px;
  font-family: poppins;
}

@media only screen and (max-width: 2000px) {
  ::placeholder {
    color: #1f2022;
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .searchTxt {
    margin-top: 15px;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .BlacksearchTxt {
    background: transparent;
    color: #1f2022;
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .booking-and-contact-list li {
    list-style-type: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    color: white;
  }
  .booking-item {
    color: white;
  }

  .Blackbooking-item {
    color: #1f2022;
  }

  .faphone {
    color: white;
  }

  .Blackfaphone {
    color: #1f2022;
  }

  /* nav bar lower sec */

  .NavBar-title {
    font-size: 13px;
    color: white;
  }
  .BlackNavBar-title {
    font-size: 13px;
    color: #1f2022;
  }
  /* scrolled nav bar */

  .scrolled-booking-btn {
    background-color: transparent;
    padding: 15px 45px;
    border: 1px solid #1f2022;
    color: #1f2022;
    font-size: 13px;
    transition: linear 0.5s;
  }
}

@media only screen and (max-width: 1397px) {
  ::placeholder {
    color: #1f2022;
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  .searchTxt {
    margin-top: 15px;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  .BlacksearchTxt {
    background: transparent;
    color: #1f2022;
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  .booking-and-contact-list li {
    list-style-type: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 11px;
    color: white;
  }
  .booking-item {
    color: white;
  }

  .Blackbooking-item {
    color: #1f2022;
  }

  .faphone {
    color: white;
  }

  .Blackfaphone {
    color: #1f2022;
  }

  /* nav bar lower sec */

  .NavBar-title {
    font-size: 11px;
    color: white;
  }
  .BlackNavBar-title {
    font-size: 11px;
    color: #1f2022;
  }
  /* scrolled nav bar */

  .scrolled-booking-btn {
    background-color: transparent;
    padding: 15px 45px;
    border: 1px solid #1f2022;
    color: #1f2022;
    font-size: 11px;
    transition: linear 0.5s;
  }
}

@media only screen and (max-width: 1351px) {
  .mobile-nav-sec {
    display: flex;
  }

  .New-nav-bar-sec {
    display: none;
  }

  .scroll-navbar {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .BlackMobilephone {
    display: none;
  }

  .mobile-nav-item img {
    width: 80px;
    margin: 10px;
  }

  .mobile-nav-item {
    width: auto;
  }
  .Mobilephone {
    display: none;
  }
  .BlackMobilephone {
    display: none;
  }

  .Justify-btn,
  .BlackJustify-btn {
    width: auto;
  }
}
