.enquireBtn {
  color: #b57d4f;
  padding: 10px 20px;
  border: 1px solid #b57d4f;
  text-decoration: none;
  background-color: transparent;
}

.enquireBtn:hover {
  text-decoration: none;
  cursor: pointer;
}
