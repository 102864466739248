@import url("https://fonts.googleapis.com/css?family=poppins");

.terms-agree-sec {
  position: fixed;
  top: 84vh;
  left: 0;
  width: 100%;
  z-index: 1500;
  height: 160px;
  background-color: #0b0b45;
  text-align: center;
  display: flex;
  color: white;
  font-family: "poppins";
  align-items: center;
  padding: 5px 10px;
}
.agree-term {
  display: flex;
}

.agree-des {
  font-size: 0.9rem;
  text-align: center;

  color: white;
  font-family: "poppins";
}
.agree-btn-sec {
  align-items: center;
  display: flex;
  padding-left: 3vw;
}

.agree-btn {
  background: transparent;
  color: white;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  border: 1px solid white;
  font-size: 0.85rem;
  cursor: pointer;
}
