.offer-header {
  width: 100%;

  background-image: url("../images/offers.webp");
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-size: cover;
  height: 630px;
  display: block;
  position: relative;
}
/* video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -999;
  } */
.header-txt {
  color: white;
  position: absolute;

  top: 50%;
}

.header-txt p {
  font-size: 15px;
  text-transform: uppercase;
}
.header-txt h1 {
  font-size: 4rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.header-txt h4 {
  font-size: 15px;
  font-weight: lighter;
}

.CheckDate {
  display: block;
}

@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
}
