.gift-logo-sec {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gift-logo-bar {
  display: flex;
  width: 75%;
  justify-content: center;
  align-items: center;
}
.gift-logo {
  width: 150px;
  justify-content: center;
  align-items: center;
}

.gift-home {
  background-color: #ecebde;
  display: flex;
}
