.phoneNUmber-section {
  padding: 15px;
}

.phone1 {
  color: white;

  width: 3vw;
  height: 20px;
}
.phone2 {
  color: black;

  font-size: 1rem;

  width: 3vw;
  height: 20px;
}

.phoneNumber {
  color: black;
  position: absolute;
  top: 12vh;
  right: 30vw;
}
.phoneNumber.clicked {
  display: none;
}

.phoneNumber li {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  list-style: none;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
