.NoofoRoom {
  width: 40vw;
  font-size: 15px;
  height: 10vh;
  align-items: center;
}
.Addrooms-upper-sec {
  background-color: white;
  display: flex;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.AddRomms-lower-sec {
  width: 300px;
  display: flex;
  background-color: white;
  padding-bottom: 10px;
  padding-top: 10px;
}
.adding {
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}
.adding1 {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}
.adding1 p {
  padding-left: 10px;
  padding-right: 10px;
}
.adding p {
  padding-left: 10px;
  padding-right: 10px;
}
.fontSize {
  font-size: 25px;
}
.end {
  display: end;
}
