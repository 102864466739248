@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.BgContent-sec {
  width: 100%;
}
.row {
  margin: 0;
  width: 100%;
}
.vr-content {
  margin-top: 5vh;
  background-image: url("../images/tourImage.webp");
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  height: 600px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.hide {
  display: none;
}
.header-section {
  position: relative;
  top: 30%;
  width: 45%;
  display: block;
  float: left;
  padding-left: 2vw;
  padding-right: 2vw;
}

.semi-header {
  text-align: end;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.7rem;
  letter-spacing: 0.075rem;
  line-height: 1rem;
}
.vr-header {
  text-align: end;
  color: white;
  font-family: Martel;
  font-weight: 300;
  font-size: 2.4rem;
}
.tour-desc {
  padding-right: 5vw;
  color: white;
  font-family: poppins-regular;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.075rem;
  line-height: 1.7rem;
}
.vr-link {
  color: white;
  font-family: poppins-regular;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
}
.vr-link:hover {
  color: white;
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
}

@media only screen and (max-width: 1200px) {
  .header-section {
    top: 20%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1000px) {
  .header-section {
    top: 20%;
    justify-content: center;
    text-align: center;
    align-items: center;
    float: none;

    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .semi-header,
  .vr-header,
  .vr-link {
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
  }
  .tour-desc {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 800px) {
  .header-section {
    top: 10%;
    justify-content: center;
    text-align: center;
    align-items: center;
    float: none;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .semi-header,
  .vr-header,
  .vr-link {
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
  }
  .tour-desc {
    padding-left: 1px;
    padding-right: 1px;
  }
}

@media only screen and (max-width: 600px) {
  .header-section {
    top: 2%;
    justify-content: center;
    text-align: center;
    align-items: center;
    float: none;

    padding-left: 0px;
    padding-right: 0px;
  }
  .semi-header,
  .vr-header,
  .vr-link {
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
  }
  .tour-desc {
    padding-left: 0px;
    padding-right: 0px;
  }
}
