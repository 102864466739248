@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.ExpMainHome-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  height: 87vh;
  border-bottom: 2px solid #b57d4f;
  display: block;
  position: relative;

  background-size: cover;
  background-position: center;
}
video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
}
.header-txt {
  color: white;
  position: absolute;

  top: 50%;
}
.advanced-booking-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.advanced-header h1 {
  font-size: 2.5rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Martel";
  color: #b57d4f;
}

.details-sec {
  text-align: left;
  display: flex;
  padding-left: 10vw;
  padding-right: 10vw;
}
.header-txt p {
  font-size: 15px;
  text-transform: uppercase;
}
.header-txt h1 {
  font-size: 4rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.header-txt h4 {
  font-size: 15px;
  font-weight: lighter;
}

.CheckDate {
  display: block;
}
.ExpHomelink {
  justify-content: center;
  text-align: center;
  font-family: Poppins-regular;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #b57d4f;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  transition: 500ms;
}

.ExpHomelink:hover {
  justify-content: center;
  text-align: center;
  font-family: Poppins-regular;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #b57d4f;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 40px;
  transition: 500ms;
}

.exHome-header-img-sec1 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/Selected/experiences/Home/exHomeLandScape1.png");
  border-top: 3px solid #b57d4f;
}

@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
}

@media only screen and (max-width: 1200px) {
  .details-sec {
    display: block;
    text-align: justify;
  }
}
