@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.description {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.header1 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 2.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 4rem;
}
.Exheader {
  height: 50px;
  font-family: Martel;
  font-weight: 400;
  color: #b57d4f;
}
.header3 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 36px;
}

.desc1 {
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 15px;
  line-height: 2.2rem;
}
.desc2 {
  height: 80px;
  text-align: center;
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
}
.desc3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
}

.link1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.109rem;
  font-size: 14px;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  color: #b57d4f;
  padding-left: 5vw;
  padding-right: 5vw;
}
.link1:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}
.link2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  justify-content: center;
  color: #b57d4f;
}
.link2:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}
.link3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  color: #b57d4f;
}

.link3:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.image img {
  transition: 2s;
}
.image:hover img {
  transform: scale(1.1);
  transition: 2s;
}

@media only screen and (max-width: 1200px) {
  .middle-sec {
    justify-content: center;
    gap: 0;
    align-items: center;
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .link1,
  .link2,
  .link3 {
    justify-content: center;

    text-align: center;
  }
}
