.item1 {
  grid-area: img1;
  overflow: hidden;
}
.item2 {
  grid-area: img2;
  overflow: hidden;
}
.item3 {
  grid-area: img3;
  overflow: hidden;
}
.item4 {
  grid-area: img4;
  overflow: hidden;
}
.item5 {
  grid-area: img5;
  overflow: hidden;
}
.item6 {
  grid-area: img6;
  overflow: hidden;
}
.item7 {
  grid-area: img7;
  overflow: hidden;
}

.item1,
.item2,
.item3,
.item4,
.item5,
.item6,
.item7 {
  cursor: pointer;
}

.item1:hover img {
  transform: scale(1.3);
}
.item2:hover img {
  transform: scale(1.3);
}
.item3:hover img {
  transform: scale(1.3);
}
.item4:hover img {
  transform: scale(1.3);
}
.item5:hover img {
  transform: scale(1.3);
}
.item6:hover img {
  transform: scale(1.3);
}
.item7:hover img {
  transform: scale(1.3);
}

.grid-container {
  display: grid;
  gap: 50px;

  grid-template-areas:
    "img1  img2 img3"
    "img4 img5 img3"
    "img6 img5 img7";
}
.grid-container > div {
  text-align: center;
}

.grid-container img {
  transition: transform 2s;
}

.link {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #876729;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  transition: 500ms;
}
.link:hover {
  text-decoration: underline;
  color: #876729;
  gap: 20px;
  transition: 500ms;
}

.dicover-more {
  cursor: pointer;
  transition: transform 2s;
}
.hide {
  display: none;
}
