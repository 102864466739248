@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");

.checkOut-section {
  width: 100%;
  background-color: white;
  position: relative;
  height: 10vh;
  color: black;
  display: block;
}
.checkItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-item-section {
  align-items: center;
  padding: 5px 10px;
  justify-content: center;
}

.checkIn {
  padding-top: 2vh;
  font-size: 0.7rem;
  font-family: "Montserrat";
  color: #1f2022;
  font-weight: 600;
}

.check-item {
  justify-content: left;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.check-item p {
  padding-right: 10px;
}
input {
  border: none;
  font-size: 15px;
}
::placeholder {
  color: black;
  font-weight: 5px;
}

.no-outline:focus {
  outline: 2px solid black;
  border-radius: 2px;
}
.calender-sec {
  position: absolute;
  left: 20vw;
}
.Addroom-sec {
  position: absolute;
  left: 38vw;
  background-color: white;
  align-items: center;
  padding: 5px 10px;

  border-radius: 5px;
}

.hide {
  display: none;
}
.show {
  display: flex;
}

@media only screen and (max-width: 1496px) {
  .checkOut-section {
    display: none;
  }
}
