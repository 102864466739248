@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.MemberCard {
  justify-content: center;
  display: flex;
}
.ourTeam-sec {
  width: 1200px;
  display: flex;
}
.Member-sec {
  justify-content: center;
  display: flex;
}
.teamMemberImg {
  justify-content: center;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.header {
  text-align: center;
  justify-content: center;
  color: #b57d4f;
}
.header {
  font-family: Martel;
  color: #b57d4f;
  font-weight: 400;
}

.header a {
  color: #b57d4f;
  display: flex;
  justify-content: center;

  font-family: poppins-regular;
}

.date-team {
  padding-top: 20px;
}
.link11,
.content,
.date-team {
  justify-content: center;
  text-align: center;
}

.Tdesc {
  height: 150px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  font-family: poppins-regular;
}
.content a {
  justify-content: center;
}

.link11 {
  font-family: poppins-regular;
  font-weight: 600;
  letter-spacing: 0.109rem;
  font-size: 14px;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  text-align: center;
  transition: 300ms;
  color: #b57d4f;
}

.link11:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}
.date-team {
  margin-top: 10px;
  display: flex;
}

.header2 {
  height: 55px;
  font-family: Martel;
  font-size: 25px;
  text-align: center;
  color: #b57d4f;
}
.hide {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .ourTeam-sec {
    width: 800px;
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .ourTeam-sec {
    width: 700px;
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .ourTeam-sec {
    width: 800px;
    display: flex;
  }
}
