.btn1 {
  border: 1px solid white;
  background: transparent;
  padding: 8px 18px;
  font-size: 1rem;
  color: white;
  transition: 0.3s all ease-out;
  cursor: pointer;
}

.btn2 {
  border: 1px solid black;
  background: transparent;
  padding: 8px 18px;
  font-size: 1rem;
  color: black;
  transition: 0.3s all ease-out;
  cursor: pointer;
}
.btn2:hover {
  color: white;
  background: black;
}
