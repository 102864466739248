.weding-service-submenu {
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  list-style: none;
  text-align: start;
  text-align: start;
  justify-content: center;
}

.weding-service-submenu li {
  display: flex;
  width: fit-content;
  cursor: pointer;
  list-style: none;
  padding-left: 5vw;
  margin-bottom: 10px;
}

.weding-service-submenu.clicked {
  display: none;
}

.submenu-item {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
}
.service-submenu li a {
  color: black;
}
