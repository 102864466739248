@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.image-slider {
  position: relative;

  width: 600px;
  height: 465px;
}
.slider-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slick-arrow.slick-prev {
  position: absolute;
  top: 100%;
  left: 90%;
  cursor: pointer;
}

.slick-arrow.slick-next {
  position: absolute;
  right: 0%;
  cursor: pointer;
}

.nav-arrow {
  font-size: larger;
}

.slider-header {
  font-family: Martel;
  font-weight: 400;
  font-size: 36px;
  color: #b57d4f;
}
.slider-desc {
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
}
.slider-link {
  padding-left: 20px;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0.109rem;
  font-size: 14px;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  color: #b57d4f;
}
.slider-link:hover {
  text-decoration: underline;
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}

.Arrow2,
.Arrow1 {
  font-size: 35px;
  font-weight: bolder;
  color: #b57d4f;
}

.Arrow1:hover,
.Arrow2:hover {
  color: #b57d4f;
  cursor: pointer;
}
.Arrow2 {
  display: none;
}

.Arrow1 {
  padding-left: 10px;
  float: right;
}
.slidingImage-sec1 {
  display: none;
}
.slider-data-sec {
  padding-left: 5vw;
  padding-right: 5vw;
}

.roomsSlider-booking {
  display: flex;
  justify-content: space-between;
}

.hide {
  display: none;
}

.featureItem {
  font-size: 15px;
}
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1400px) {
  .slidingImage-sec1 {
    display: none;
  }

  .Arrow1 {
    display: none;
  }
  .Arrow2 {
    display: block;
  }

  .slide-arrow2 {
    position: absolute;
    left: 2vw;
    top: 40%;
  }

  .slide-arrow3 {
    position: absolute;
    right: 2vw;
    top: 40%;
  }
  .slidingImage-sec {
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .slider-data-sec,
  .link-arrow,
  .slider-link {
    justify-content: center;
    text-align: center;
  }

  .roomsSlider-booking {
    display: flex;
    justify-content: center;
  }

  .slider-data-sec {
    display: block;
    justify-content: center;
  }
}
@media only screen and (max-width: 900px) {
  .slidingImage-sec {
    display: none;
  }

  .slidingImage-sec1 {
    display: block;
    width: 100%;
    margin: 0;
    background-size: cover;
  }
  .Arrow1 {
    display: block;
  }
  .slide-arrowLeft {
    position: absolute;
    left: 20px;
  }
  .slide-arrowRight {
    position: absolute;
    right: 20px;
  }
}
