@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.chalet-slider-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chalet-slider-sec {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chalet-des-sec {
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.chalet-slider-img {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
  width: 60%;
}

.chalet-slider-header-sec {
  display: flex;
  justify-content: left;
  align-items: center;
}

.chalet-slider-header {
  font-family: Martel;
  font-weight: 400;
  font-size: 36px;
  color: #b57d4f;
}

.chalet-slider-des {
  font-family: poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
}

.chalet-slider-des-sec {
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}

.chalet-slider-features {
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
}
.featureItem {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
}
.chalet-slider-booking-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.chalet-slider-booking {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
}

.chalet-slider-link {
  padding-left: 20px;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0.109rem;
  font-size: 14px;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  color: #b57d4f;
}
.chalet-slider-link:hover {
  gap: 20px;
  transition: 300ms;
  color: #b57d4f;
}

@media only screen and (max-width: 1400px) {
  .chalet-slider-sec {
    display: block;
    width: 70%;
  }
  .chalet-slider-img {
    margin-bottom: 40px;
    width: 100%;
  }
  .chalet-des-sec {
    width: 100%;
  }
  .chalet-des-sec,
  .chalet-slider-header-sec,
  .chalet-slider-des-sec,
  .chalet-slider-features,
  .chalet-slider-booking-sec {
    justify-content: center;
  }

  .chalet-slider-features {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chalet-slider-booking-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chalet-slider-booking {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
