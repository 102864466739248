@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.Intro-header,
.intro-detail {
  padding-left: 20vw;
  padding-right: 20vw;
  justify-content: center;
  text-align: center;
  display: flex;
}

.Intro-header {
  font-family: Martel;
  font-weight: 400;
  font-size: 36px;
  padding-bottom: 20px;
  color: #b57d4f;
}
.intro-detail {
  padding-bottom: 20px;
}
