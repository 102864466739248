.animation {
  position: relative;
  transform-origin: left;
  overflow: hidden;
  transform: scaleX(1);
  transition: 1.2s linear;
  transition-delay: 0.5s;
}
.animation::before {
  content: "";
  /* background: #0b0b45; */
  background: url("../images/siteLogo/BoxA.png");

  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: right;
  transition: 1.2s linear;
  transition-delay: 0s;
}
.animation.active {
  transform: scaleX(1);
  transition-delay: 0s;
}

.animation.active::before {
  transform: scaleX(1);
  transition-delay: 0s;
  top: 0;
  left: 100%;
}
