@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.check-data-sec {
  display: flex;
  height: 100px;
  width: 100%;

  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: 1px solid lightgray;
}

.no-bg {
  background: transparent;
}

.booking-bar {
  justify-content: center;
  display: flex;
}
.check-part {
  width: 13vw;

  padding-top: 1.5vh;
  align-items: center;
  display: block;
}
.check-part p {
  margin: 0;

  font-family: Montserrat;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.selector-sec {
  margin: 0;
  display: flex;
  cursor: pointer;
  position: relative;
}
.check-part h3 {
  margin: 2px;

  font-family: Martel;
  font-size: 1rem;
  font-weight: 400;
}

.select-arrow {
  height: 1.5rem;
  width: 25px;
  position: absolute;
  right: 40%;
  transition: all 0.2s ease;
  transform-origin: 50% 50%;
}

.select-arrowClicked {
  height: 1.5rem;
  rotate: 180deg;
  width: 25px;
  position: absolute;
  right: 40%;
  transition: all 0.2s ease;
  transform-origin: 50% 50%;
}

.select-arrow2 {
  height: 1.5rem;
  width: 25px;
  position: absolute;
  right: 13%;
  transform-origin: 50% 50%;
  transition: all 0.2s ease;
}

.select-arrow2Clicked {
  height: 1.5rem;
  rotate: 180deg;
  width: 25px;
  position: absolute;
  right: 13%;
  transition: all 0.2s ease;
  transform-origin: 50% 50%;
}
/* .select-arrow {
  rotate: 180deg;
  transition: all 0.2s ease;
} */
.calender-sec1 {
  position: absolute;
  top: 100%;
  background-color: #ecebde;
  left: -50%;
}

.Addroom-sec1 {
  display: flex;
  width: 300px;
  background-color: #ecebde;
  position: absolute;
  top: 100%;
  left: -50%;
}

::placeholder {
  color: #1f2022;
  font-weight: 5px;
  font-family: Martel;
  font-size: 1rem;
  font-weight: 400;
}

.check_search-tbn {
  border: 1px solid #b57d4f;
  background-color: transparent;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  color: #b57d4f;
  font-family: Montserrat;
}
@media only screen and (max-width: 2000px) {
  .booking-bar {
    max-width: 1076px;
    align-items: center;
    display: flex;
    flex-wrap: unset;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    min-height: 100px;
    padding: 20px 134px;
  }
  .check-part {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
  }
  .check-part p {
    margin: 0;

    font-family: Montserrat;
    font-size: 0.7rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .selector-sec {
    margin: 0;
    display: flex;
    cursor: pointer;
    position: relative;
  }
  .check-part h3 {
    font-family: Martel;
    font-size: 1rem;
    font-weight: 400;
  }

  .select-arrow {
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 30%;

    transition: all 0.2s ease;
    transform-origin: 50% 50%;
  }

  .select-arrowClicked {
    rotate: 180deg;
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 30%;
    transition: all 0.2s ease;
    transform-origin: 50% 50%;
  }

  .select-arrow2 {
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 13%;

    transform-origin: 50% 50%;
    transition: all 0.2s ease;
  }

  .select-arrow2Clicked {
    rotate: 180deg;
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 13%;
    transition: all 0.2s ease;
    transform-origin: 50% 50%;
  }

  /* .select-arrow {
    rotate: 180deg;
    transition: all 0.2s ease;
  } */
  .calender-sec1 {
    position: absolute;
    top: 100%;
    z-index: 2000;
    left: -50%;
  }

  .Addroom-sec1 {
    position: absolute;
    top: 100%;
    z-index: 2000;
    display: block;
  }

  .selector-sec::placeholder {
    color: white;
    font-weight: 5px;
    font-family: Martel;
    font-size: 1rem;
    font-weight: 400;
  }
  .no-bg {
    background-color: transparent;
  }

  .check_search-tbn {
    border: 1px solid #b57d4f;
    background-color: transparent;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #b57d4f;
    font-family: Montserrat;
    font-size: 13px;
  }
}
@media only screen and (max-width: 1400px) {
  .check-data-sec {
    padding: 0;
    border-bottom: 2px solid rgba(135, 103, 41, 0.2);
  }
  .booking-bar {
    max-width: 1200px;
    align-items: center;
    display: flex;
    flex-wrap: unset;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    padding: 20px 130px;
  }
  .check-part {
    display: block;
    width: 230px;
    padding-left: 50px;
  }
  .check-part p {
    margin: 0;

    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .selector-sec {
    margin: 0;
    display: flex;
    cursor: pointer;
    position: relative;
  }
  .check-part h3 {
    font-family: Martel;
    font-size: 1rem;
    font-weight: 400;
  }

  .select-arrow {
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 20%;
    transform-origin: 50% 50%;
    transition: all 0.2s ease;
  }

  .select-arrowClicked {
    rotate: 180deg;
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 20%;
    transform-origin: 50% 50%;
    transition: all 0.2s ease;
  }

  .select-arrow2 {
    height: 1.5rem;
    width: 25px;
    position: absolute;
    right: 0%;
    transform-origin: 50% 50%;
    transition: all 0.2s ease;
  }

  .select-arrow2Clicked {
    height: 1.5rem;
    rotate: 180deg;
    width: 25px;
    position: absolute;
    right: 0%;
    transition: all 0.2s ease;
    transform-origin: 50% 50%;
  }
  /* .select-arrow {
    rotate: 180deg;
    transition: all 0.2s ease;
  } */
  .calender-sec {
    position: absolute;
    top: 100%;
    left: -50%;
  }

  .Addroom-sec {
    position: absolute;
    top: 100%;
    left: -50%;
  }

  ::placeholder {
    color: #1f2022;
    font-weight: 5px;
    font-family: Martel;
    font-size: 1rem;
    font-weight: 400;
  }

  .check_search-tbn {
    border: 1px solid #b57d4f;
    background-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #b57d4f;
    font-family: Montserrat;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1200px) {
  .check-data-sec {
    display: none;
  }
}
