@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.Header-Content {
  width: 100%;
  padding-top: 8vh;
  display: flex;
  padding-bottom: 25px;
  justify-content: center;
  background-color: #ecebde;
  border-bottom: 3px solid #b57d4f;
}
.Header-Content-bar {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-sec {
  align-items: center;
}
.col {
  width: 100%;
  text-align: center;
}
.hotel-stars {
  fill: rgb(193, 152, 107);
}

.starlogo {
  width: 70px;
}
.hotel-title1 {
  margin-top: -10px;
  font-family: Martel;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.067rem;
  line-height: 4rem;
  color: #1f2022;
}
.hotel-address {
  margin-top: -1rem;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 0.089rem;
}
.hotel-link {
  text-decoration: none;
  font-family: Montserrat;
  color: gray;

  font-weight: 300;
  font-size: 0.8rem;
  letter-spacing: 0.089rem;
}
.hotel-link:hover {
  text-decoration: underline;
}

.hotel-semi-title1 {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: Martel;
  font-size: 2.3rem;
  font-weight: 400;
  color: #b57d4f;
}
.hotel-desc1 {
  font-family: poppins-regular;
  font-weight: normal;
  color: #1f2022;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

@media only screen and (max-width: 2000px) {
}
