.wedding-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  height: 87vh;
  border-bottom: 2px solid #b57d4f;
  display: block;
  position: relative;
}
/* video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -999;
  } */
.header-txt {
  color: white;
  position: absolute;

  top: 50%;
}

.header-txt p {
  font-size: 15px;
  text-transform: uppercase;
}
.header-txt h1 {
  font-size: 4rem;
  font-weight: lighter;
  padding-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.header-txt h4 {
  font-size: 15px;
  font-weight: lighter;
}

.CheckDate {
  display: block;
}

.call-us {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.call-header h1 {
  padding-left: 20vw;
  padding-right: 20vw;
  font-family: "Playfair Display", serif;
}

.Weddinghome-header-img-sec1 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 3px solid #b57d4f;
  background-position: center;
  background-image: url("../images/Selected/Wedding/home/weddingHomeLandscape1.png");
  border-bottom: 3px solid #b57d4f;
}

.Weddinghome-header-img-sec2 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 3px solid #b57d4f;
  background-position: center;
  background-image: url("../images/Selected/Wedding/home/weddingHomeLandscape2.png");
  border-bottom: 3px solid #b57d4f;
}

.Weddinghome-header-img-sec3 {
  min-height: 660px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 3px solid #b57d4f;
  background-position: center;
  background-image: url("../images/Selected/Wedding/home/weddingHomeLandscape3.png");
}
@media only screen and (max-width: 1496px) {
  .CheckDate {
    display: none;
  }
  .header-txt {
    top: 30%;
  }
}
