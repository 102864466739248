@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=poppins");
@import url("https://fonts.googleapis.com/css?family=Martel");

.about-content {
  background-color: #ecebde;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.about-hotel-bar {
  display: flex;
  width: 90%;
}

.txt-area {
  width: 100%;
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.txt-sec {
  width: 90%;
}
.img-sec {
  display: flex;
  justify-content: center;
}

.img-area {
  border-bottom: 3px solid #b57d4f;
  border-top: 3px solid #b57d4f;
  width: 600px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.header-welcome {
  font-family: Martel;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  color: #b57d4f;
  letter-spacing: 0.1rem;
  line-height: 3rem;
}
.desc-chapter-11 {
  font-family: Poppins-regular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  text-align: justify;
}
.desc-chapter-22 {
  margin-top: 30px;
  font-family: Poppins-regular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  text-align: justify;
}

.txt-sec a {
  justify-content: left;
  text-align: left;
  padding: 0;
}
.link111 {
  justify-content: left;
  text-align: left;
  font-family: Poppins-regular;
  font-weight: 600;
  letter-spacing: 0.109rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: #b57d4f;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  transition: 500ms;
}
.link111:hover {
  text-decoration: none;
  color: #b57d4f;
  gap: 20px;
  transition: 500ms;
}

.AboutHotel-img {
  background-size: cover;
  width: 550px;
}

@media only screen and (max-width: 1400px) {
  .AboutHotel-img {
    background-size: cover;
    width: 500px;

    /* width: 500px; */
  }
  .about-hotel-bar {
    display: flex;

    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .about-hotel-bar {
    display: block;

    justify-content: center;
    width: 90%;
  }
  .img-area {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
