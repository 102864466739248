.header-navigation1 {
  position: fixed;
  background: transparent;
  transition: 1s all ease;
  z-index: 999;
  transition-delay: 0.2s;
  width: 100%;
}

.header-navigation2 {
  position: fixed;
  background: white;
  color: black;
  transition: 1s all ease;
  z-index: 999;
  width: 100%;
}

.haeder-upper-sectionShow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70x;
  background: transparent;
  width: 100%;
}

.haeder-upper-sectionHide {
  display: none;
}

.header-left {
  display: flex;
  margin-left: 30px;

  justify-content: flex-start;
  align-items: center;
}

.header-left input {
  margin-left: 10px;
  font-size: 1rem;
  background: transparent;
  border: none;
}
.header-middle {
  display: flex;
  justify-content: center;
}

.header-middle img {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.header-right {
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  align-items: center;
}

.booking-section {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  margin-right: 1rem;
}

.booking-item {
  display: flex;
  align-items: center;
  height: 20px;
}
.bookbtn {
  align-items: center;
}

.linkCSS1 {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  margin-right: 1rem;
  padding: 6px 10px;
}

.linkCSS1:hover,
.linkCSS2:hover {
  color: black;
}

.linkCSS2 {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  margin-right: 1rem;
  padding: 6px 10px;
}

::placeholder {
  color: black;
  font-weight: 5px;
}

.no-outline:focus {
  outline: none;
}

.navbar {
  height: 75px;
  background: transparent;
  position: relative;
  display: flex;
  /* get center horizontally */
  justify-content: center;
  /* get center vertically */
  align-items: center;
  width: 100vw;
  transition: 0.3s all ease;
}
.navbar2 {
  height: 75px;
  position: relative;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  transition: 0.3s all ease;
}
/* side naw bar */

.navbar3 {
  height: 75px;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background: white;
  display: inline-block;
}

.navbar4 {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background: white;
  display: none;
}

.navbar-logo {
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* set auto padding*/
  justify-content: space-between;
  width: 10rem;
}

.nav-items {
  display: flex;
  list-style: none;
  text-align: center;
  width: 90vw;
  justify-content: center;
  margin-right: 1rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 10vh;
}

.nav-item1 {
  text-decoration: none;
  position: relative;
  color: black;
  font-size: 0.95rem;
  padding-bottom: 2px;
  margin-right: 2rem;
  padding: 3px 5px;
  border-radius: 5px;
  width: 100%;
}
.nav-item1:hover {
  color: black;
}

.nav-item2 {
  text-decoration: none;
  position: relative;
  color: white;
  font-size: 0.95rem;
  padding-bottom: 2px;
  margin-right: 2rem;
  padding: 6px 5px;
  border-radius: 5px;
}

.nav-item2:hover {
  color: black;
}
/* .nav-item a {
  text-decoration: none;
  position: relative;
  color: black;
  font-size: 1rem;
  padding-bottom: 2px;
  margin-right: 2rem;
  padding: 6px 5px;
  border-radius: 5px;
} */

.booking-button-sec {
  display: flex;
  align-items: center;
}

.nav-item1::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0px;
  transition: transform 0.3s ease;
  transform: scale(0, 1);
}
.nav-item1:hover::after {
  width: 100%;

  transform: scale(1, 1);
}

.subMenu-section {
  position: absolute;
  display: block;
  top: 80px;
  width: 100%;
  height: 360px;
  overflow-y: auto;
  background-color: white;
}

.nav-items-side {
  position: relative;
  top: 0px;
  display: block;
  list-style: none;
  text-align: left;
  width: 100%;
  justify-content: center;
  background-color: white;
}

.nav-items-side2 {
  display: none;
}
.Fasearch {
  display: flex;
}

.Fabars {
  display: none;
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 40px;
}
.cross {
  z-index: 1004;
  display: block;
  background-color: white;
  width: 100%;
  height: 80px;
  padding-top: 30px;
}
.FaTimes {
  cursor: pointer;
  font-size: 40px;
  margin-left: 30px;

  display: block;
}

.hide {
  display: none;
}

.aviyanaSideBar {
  display: none;
}

@media only screen and (max-width: 1496px) {
  .Fasearch {
    display: none;
  }
  .bookbtn {
    position: absolute;
    top: 40px;
    right: 30px;
  }

  .booking-section {
    display: none;
  }
  .nav-items {
    display: none;
  }
  .callIcon {
    display: none;
  }
  .Fabars {
    display: flex;
    font-size: 30px;
  }
  .navbar {
    height: 30px;
  }
  .navbar2 {
    height: 30px;
  }

  .aviyanaSideBar {
    z-index: 1001;
    position: relative;
    display: block;
    list-style: none;
    text-align: left;
    width: 100%;

    background-color: white;
  }
}
